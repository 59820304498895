<template>
  <div>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort">
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th></vs-th>
          <vs-th sort-key=""></vs-th>
          <vs-th sort-key="">SAF ID</vs-th>
          <vs-th sort-key="">SAF Type</vs-th>
          <vs-th sort-key="">Warehouse Code</vs-th>
          <vs-th sort-key="">Document Date</vs-th>
          <vs-th sort-key="" v-if="status === 3">Posting Date</vs-th>
          <vs-th sort-key="">System Data</vs-th>
          <vs-th sort-key="">Counting Data</vs-th>
          <vs-th sort-key="">SAF Value</vs-th>
          <vs-th sort-key="" v-if="status === 3 && !isReject">SAF Value Execute</vs-th>
          <vs-th sort-key="" v-if="status === 3">Status</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div class="vx-input-group flex">
                <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                           v-if="hasPermission('view')"
                           title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id, tr.saf_type)"/>
                <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x"
                           v-if="status === 0 && hasPermission('cancel')"
                           title="Canceled" style="margin-right: 5px" @click="handleDelete(tr.id, tr.saf_type)"/>
                <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                           v-if="isApproval === 1 && hasPermission('edit')"
                           title="Approve" style="margin-right: 5px" @click="handleExecute(tr.id)"/>
                <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x"
                           v-if="isApproval === 1 && hasPermission('cancel')"
                           title="Reject" style="margin-right: 5px" @click="handleReject(tr.id)"/>

              </div>
            </vs-td>
            <vs-td></vs-td>
            <vs-td>{{ tr.code }}</vs-td>
            <vs-td>{{ tr.saf_type }}</vs-td>
            <vs-td>{{ tr.warehouse_code }}</vs-td>
            <vs-td>{{ tr.counting_date }}</vs-td>
            <vs-td v-if="status === 3">{{ tr.posting_date }}</vs-td>
            <vs-td>{{ tr.of_qty }}</vs-td>
            <vs-td>{{ tr.counting_qty }}</vs-td>
            <vs-td>{{ priceFormat(tr.saf_value) }}</vs-td>
            <vs-td v-if="status === 3 && !isReject">{{ priceFormat(tr.last_map || 0) }}</vs-td>
            <vs-td v-if="status === 3">{{ tr.remark }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>

    <vs-prompt
      color="primary"
      title="Confirmation"
      @cancel="submitPromptConfirm = false"
      @accept="acceptExecuteSaf"
      @close="closePrompt"
      :accept-text="'Yes'"
      :active.sync="submitPromptConfirm">
      <div class="con-exemple-prompt">
        <div class="vx-row mb-12 mt-3">
          <div class="vx-col w-full">
            <span>Posting date</span>
          </div>
          <div class="vx-col w-full">
            <div class="vx-col">
              <template>
                <datepicker
                  name="posting_date_execute"
                  :inline="false"
                  v-model="posting_date_execute"
                  :clearable="false"
                  :disabledDates="disabledDates"
                  placeholder="Select Date">
                </datepicker>
              </template>
            </div>
          </div>
        </div>

      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    draw: {
      type: Number,
    },
    countingDate: {
      type: Date,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    selectedWarehouse: {
      type: Object,
      default: null,
    },
    isApproval: {
      type: Number,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    },
    tab: {
      type: String,
      default: null,
    },
    isReject: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      deleteId: null,
      action: null,
      table: this.tableDefaultState(),
      checkedAll: false,
      checked: [],
      selectedRouteAssignmentToAssign: [],
      activeTab: 0,
      activeStatus: 1,
      isApprovalMenu: 0,
      isRejected: this.isReject,
      submitPromptConfirm: false,
      posting_date_execute: null,
      disabledDates: {},
      permission: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-adjustment-form/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: this.status,
            remark: this.remark,
            is_approval: this.isApproval ? this.isApproval : null,
            warehouse_id: this.selectedWarehouse ? this.selectedWarehouse.id : null,
            counting_date: (this.countingDate)
              ? moment(this.countingDate).format("YYYY-MM-DD")
              : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleApprove(id) {
      this.approveID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to approve this data",
        accept: this.acceptApprove,
      });
    },
    acceptApprove() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/approve-on-saf/" + this.approveID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully send to approved request",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleDelete(id, safType) {
      this.deleteId = id;
      this.safType = safType;

      if (safType === 'Adjustment Stock') {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this data",
          accept: this.acceptDelete,
        });
      } else if (safType === 'Interwarehouse') {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this data",
          accept: this.acceptDeleteInterWarehouse(),
        });
      }
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/cancel/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    acceptDeleteInterWarehouse() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/cancel-interwarehouse/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleExecute(id) {
      this.executeID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to approved this data",
        accept: this.acceptExecute,
      });
    },
    acceptExecute() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/approve-on-approval/" + this.executeID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully approved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReject(id) {
      this.rejectID = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to reject this data",
        accept: this.acceptReject,
      });
    },
    acceptReject() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/reject/" + this.rejectID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully rejected",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleExecuteSaf(data) {
      console.log(data);

      this.disabledDates = {
        from: new Date(Date.now()),
        to: new Date(moment(data.counting_date).format("YYYY-MM-DD"))
      }

      this.posting_date_execute = data.document_date
      this.submitPromptConfirm = true
      this.id = data.id;
      this.detail = true;
    },
    acceptExecuteSaf() {
      let form = new FormData();
      form.append("posting_date", moment(this.posting_date_execute).format("YYYY-MM-DD"));

      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/execute/" + this.id, form,)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully execute",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    acceptExecuteSafInterwarehouse() {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/execute-interwarehouse/" + this.executeSafID)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully execute",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDetail(id, SafType) {
      if (this.tab === "WaitingApproval") {
        this.activeTab = 0;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'ApprovedApproval') {
        this.activeTab = 1;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'RejectedApproval') {
        this.activeTab = 2;
        this.isApprovalMenu = 1;
      } else if (this.tab === 'WaitingSaf') {
        this.activeTab = 1;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'ApprovedSaf') {
        this.activeTab = 2;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'ExecutedSaf') {
        this.activeTab = 3;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'ClosedSaf') {
        this.activeTab = 4;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'CanceledSaf') {
        this.activeTab = 5;
        this.isApprovalMenu = 0;
      } else if (this.tab === 'CanceledExecuteSaf') {
        this.activeTab = 6;
        this.isApprovalMenu = 0;
      }

      this.$router.push({
        name: "saf-view",
        query: {id: id, SafType: SafType, tab: this.activeTab, is_approval: this.isApprovalMenu},
      });
    },
    utcToLocal(val) {
      if (val) {
        return moment(val)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    onchangeSelectedRouteAssignmentToAssign(e, index, row) {
      if (!this.checked[index]) {
        var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
          return d.id == row.id;
        });

        if (fitered.length == 0) {
          this.selectedRouteAssignmentToAssign.push(row);
        }
      } else {
        this.selectedRouteAssignmentToAssign.forEach((val, i) => {
          if (val.id == row.id) {
            this.selectedRouteAssignmentToAssign.splice(i, 1);
          }
        });
        this.checkedAll = false;
      }
    },
    closePrompt() {
      this.submitPromptConfirm = false
    },
    getPermission() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/permission", {
          params: {
            module: "saf",
            source: "WMS"
          }
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.permission = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
  },
  mounted() {
    this.getPermission();
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },
};
</script>
